.expandButton {
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: center;
}

.expandButtonImg {
  transition: all 0.1s linear;
  background-color: #2a4cbe;
  border-radius: 100rem;
}

.expandButtonImg:hover {
  margin-top: -0.15rem;
  cursor: pointer;
}
