@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo/Arvo-Regular.ttf');
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --navBarHeight: 5rem;
  --centralSpanWidth: 100rem;
  --buttonSecondary: rgb(40, 134, 206);
  --smallWidthBreakpoint: 1100px;
}

.pointer-hover:hover {
  cursor: pointer;
}

.noscroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.noscroll {
  scrollbar-width: none; /* for Firefox */
}

.invertFilter {
  filter: invert(1);
  -webkit-filter: invert(1);
}

.alice-carousel__dots {
  margin: -2rem 0 1rem 0 !important;
}


