.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s ease-in-out;
}

.blackout {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: 15;
  position: fixed;
}

.modal {
  box-sizing: border-box;
  background-color: white;
  border-radius: 3px;
  z-index: 15;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerContainer {
  display: flex;
  justify-content: space-between;;
}

.headerSpacer {
  width: 2.5rem;
  height: 0rem;
}

.closeModalButton {
  height: 2.5rem;
  transition: opacity 0.15s ease-out;
  margin: 2rem 2rem 0 0;
  z-index: 15;
}

.closeModalButton:hover {
  opacity: 0.6;
  cursor: pointer;
}

.closeModalButton:active {
  opacity: 1;
}

.sidePanel {
  width: 15rem;
  border-top-left-radius: 3px;
  box-sizing: border-box;
  display: none;
}

.mainPanel {
  display: flex;
  flex-direction: column;
  width: 60rem;
  box-sizing: border-box;
}

.sectionHeader {
  width: 100%;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  color: rgb(0, 0, 0);
  display: flex;
}

.sectionContainer {
  margin-bottom: 2rem;
  width: 100%;
}

.sectionInputsContainer {
  display: flex;
}

.inputIcon {
  height: 1.9rem;
  margin: 0 0.5rem 0 0.5rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
}
