@import "../../../globalStyles.scss";

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  margin-top: -3rem;
  font-family: "Arvo";
  @include responsive(padding, 3rem 1rem 3rem 1rem, 3rem, 3rem, 3rem);
}

.simple {
  text-transform: uppercase;
  padding: 3rem;
  margin-top: 3rem;
}

.textAreaContainer {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.textArea {
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
  border-radius: 1px;
  width: calc(100% - 1rem);
  padding: 1.5rem 2rem 2rem 2rem;
  margin: 0.5rem;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
}

.textArea:focus {
  border: 1px solid rgb(42, 109, 224);
}

textarea {
  resize: none;
}

.nonButtonContainer {
  overflow-y: scroll;
  mask-image: linear-gradient(
    to bottom,
    black calc(100% - 100px),
    transparent 100%
  );
}

.submitButtonContainer {
  display: flex;
  background-color: #6ca0bd;
  padding: 0.6rem 1rem 0.6rem 1rem;
  color: white;
  border-radius: 1px;
}
