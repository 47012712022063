.navButton {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 2px;
  transition: opacity 0.1s ease-in;
  color: white;
  box-sizing: border-box;
  font-family: 'Open Sans';
}

.navButton:hover {
  cursor: pointer;
}

.navButtonBlack {
  color: white;
  background-color: #6ca0bd;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
