.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  font-family: "Raleway";
}

.qContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.aContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.q {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
}

.question {
  font-size: 1.4rem;
  margin-top: 0.1rem;
  box-sizing: border-box;
  font-weight: bold;
}

.a {
  font-size: 1.3rem;
  margin-right: 1rem;
}

.answer {
  font-size: 1.1rem;
  margin-top: 0.1rem;
  box-sizing: border-box;
  line-height: 1.6rem;
}
