.header {
  font-size: 2.3rem;
  margin-bottom: 2rem;
  font-family: 'Arvo';
}

.incentive {
  font-size: 2rem;
  color: rgb(60, 163, 60);
  margin: 1rem 0 0 0rem;
  font-weight: bold;
  font-family: 'Arvo';
}

.text{
  font-family: "Raleway";
}