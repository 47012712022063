.backgroundContainer {
  background-color: black;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  transition: background-image 0.3s ease-in;
  position: absolute;
  background-attachment: scroll;

}

.childrenContainer {
  width: 100%;
  position: sticky;
  display: flex;
  justify-content: center;
}

.container {
  background-color: black;
  width: 100%;
  position: sticky;
}
