.myButton {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.17s linear;
  animation-timing-function: fade-in-out 1s;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: none;
}

.myButton:hover {
  cursor: pointer;
  opacity: 0.8;
}
