@import "../../../globalStyles.scss";

.container {
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100%;
  height: 100%;
  font-family: "Open Sans";
  align-items: flex-start;
}

.item {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 2rem;
  color: rgb(222, 222, 222);
}

.itemHeader {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: capitalize;
}

.itemText {
  line-height: 1.3rem;
}

.nabcepLogo {
  width: 10rem;
  margin: 2rem 0 0 0;
}

.logoSmallContainer {
  display: flex;
  justify-content: space-between;

  @include responsive("flex-direction", column, column, column, column);
}

.logoSmall {
  width: 10rem;

  @include responsive("margin-left", 0)
}
