.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  padding: 3rem;
  margin-top: -3rem;
  font-family: "Arvo";
}

.nonButtonContainer {
  overflow-y: scroll;
  mask-image: linear-gradient(
    to bottom,
    black calc(100% - 100px),
    transparent 100%
  );
}

.submitButtonContainer{
  display: flex;
  background-color: #6ca0bd;
  padding: 0.6rem 1rem 0.6rem 1rem;
  color: white;
  border-radius: 1px;
}
