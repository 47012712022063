textarea,
input {
  outline: none;
}

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  
}

.label {
  width: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0rem 0.5rem 0rem 0.5rem;
  text-transform: capitalize;
}

.input {
  height: 2.5rem;
  border: none;
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0.5rem;
  background-color: rgb(240, 240, 240);
  border-radius: 0;
  padding-left: 0.5rem;
  transition: border 0.15s linear;
  border: 1px solid rgb(240, 240, 240);
}

.input:focus {
  border-bottom: 1px solid rgb(66, 113, 183);
}
