.header {
  text-align: left;
  font-size: 2rem;
  padding: 3rem;
  box-sizing: border-box;
  font-family: 'Arvo';
}

.text {
  width: 100%;
  padding: 3rem;
  box-sizing: border-box;
  font-family: 'Raleway';
}

.grid {
  display: grid;
  padding: 0 3rem 2rem 3rem;
  width: 100%;
}

.tileGridContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  grid-template-columns: 50% 50%;
  padding: 2rem;
  font-family: 'Raleway';
}
