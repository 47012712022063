@import "../../globalStyles.scss";

.outerContainer {
  display: flex;
  justify-content: center;
  --color: #565656;
}

.container {
  padding-top: 1rem;
  height: 4rem;
  background-color: var(--color);
  position: fixed;
  color: white;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease-in-out;

  @include responsive("width", 100%, 70%, 50%, 40%);
  @include responsive("border-radius", 0, 2px, 2px, 2px);
  @include responsive("font-size", 0.9rem, unset, unset, unset);
}

.quoteButton {
  background-color: white;
  color: var(--color);
  padding: 0.1rem 1.5rem 0.1rem 1.5rem;
  border: 2px solid white;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
  font-weight: bold;

  @include responsive("font-size", 0.7rem, unset, unset, unset);
}

.quoteButton:hover {
  cursor: pointer;
  background-color: var(--color);
  color: white;
}

.contactButton {
  color: white;
  padding: 0.1rem 1.7rem 0.1rem 1.7rem;
  transition: all 0.3s ease-in-out;
  font-weight: bold;

  @include responsive("font-size", 0.7rem, unset, unset, unset);
  @include responsive("display", none, block, block, block);
}

.contactButton:hover {
  cursor: pointer;
  opacity: 0.6;
}
